import { required, email, minLength, maxLength, helpers } from "vuelidate/lib/validators";

// Validación personalizada: Solo letras y espacios
export const onlyLetters = (value) => /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/.test(value);

// Validación personalizada: Sin caracteres especiales
export const noSpecialChars = (value) => /^[a-zA-Z0-9áéíóúÁÉÍÓÚñÑ\s]*$/.test(value);

// Validación personalizada: Sin espacios
export const noSpaces = (value) => !/\s/.test(value);

// Validación personalizada: Detecta si contiene comas o apóstrofes (separadores incorrectos)
export const noCommasOrApostrophes = (value) => /^[^,']*$/.test(value);

// Validación personalizada: Solo caracteres numéricos y puntos
export const noInvalidCharacters = (value) => /^[0-9.]*$/.test(value);

// Validación personalizada: Número entero o decimal con punto
export const validDecimal = (value) => /^-?\d*(\.\d+)?$/.test(value);

// Validación personalizada: Solo numericos
export const onlyNumeric = (value) => /^-?\d+$/.test(value);

export const validDateFormat = helpers.withParams(
    { type: "validDateFormat" },
    value => {
      if (!value) return false; // Permitir vacío
      const regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
      return regex.test(value);
    }
  );
  
export const validEndDate = helpers.withParams(
    { type: "validEndDate" },
    (value, parentVm) => {
        // `parentVm` es el contexto del elemento actual (action)
        if (!value || !parentVm.init_date) return true; // Permitir vacío o si no hay `init_date`
        const startDate = new Date(parentVm.init_date);
        const endDate = new Date(value);
        return endDate >= startDate; // `end_date` debe ser mayor o igual que `init_date`
    }
);

// Generador de validaciones
export const generateValidations = (fields, customRules = {}) => {
    const validations = {};

    fields.forEach((field) => {
        const defaultRules = [
            { name: "required", rule: required },
        ];

        // Reglas específicas para `email`
        if (field === "email") {
            defaultRules.push({ name: "email", rule: email });
        }

        // Reglas específicas para `password`
        if (field === "password") {
            defaultRules.push({ name: "minLength", rule: minLength(8) });
            defaultRules.push({ name: "maxLength", rule: maxLength(20) });
        }

        // Combina las reglas predeterminadas con personalizadas
        const combinedRules = [
            ...defaultRules,
            ...(customRules[field]?.map((rule) => ({ name: "custom", rule })) || []),
        ];

        // Asignar reglas al campo
        validations[field] = combinedRules.reduce((acc, { name, rule }) => {
            acc[name] = rule;
            return acc;
        }, {});
    });

    return validations;
};