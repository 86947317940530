export const errorMessages = {
    required: "Este campo es obligatorio.",
    onlyLetters: "Solo se permiten letras y espacios.",
    noSpecialChars: "No se permiten caracteres especiales ni espacios.",
    minLength: (min) => `Debe tener al menos ${min} caracteres.`,
    maxLength: (max) => `No puede tener más de ${max} caracteres.`,
    sameAs: "Las contraseñas no coinciden.",
    email: "El correo electrónico no es válido.",
    noSpaces: "No se permiten espacios.",
    onlyNumeric: "Solo se permiten valores numéricos."
};