<script>
import api from '@/helpers/api-rest/api.js';
import status from '@/helpers/general/status.js';
import { generateValidations, onlyLetters, noSpaces } from "@/helpers/validations/validationsHelpers";
import { errorMessages } from "@/helpers/validations/errorMessages";

export default {
    props:{
        showUserModal: Boolean,
        user: {type: Object, default: () => {
            return {
                name: '',
                email: '',
                password: '',
                use: '',
                center_id: null,
                project_id: null,
                role_ids: [],
                status: 1,
                center: {
                    name: '',
                },
                project: {
                    name: '',
                },
            }
        }}
    },
    data() {
        return {
            selectedRoles: [],
            userStatus: status.getList('userStatus'),
            loading: false,
            selectOptionsCenter: [],
            errorMessages
        };
    },

    validations: {
        user: generateValidations(
            ["name", "email", "dni"],
            {
                name: [onlyLetters],
                email: [noSpaces],
                dni: [noSpaces],
            }
        ),
    },

    computed: {
        modalTitle() {
        return this.user?.id ? 'Editar Usuario' : 'Añadir Usuario';
        },
        submitButtonText() {
            return this.user?.id ? 'Guardar' : 'Añadir';
        }
    },
    created() {
        this.loadSelectOptionsCenter();
        this.loadSelectRole();
    },
    methods: {
        createUser() {
            this.loading = true;
            //const apiHost = process.env.VUE_APP_API_HOST;
            const apiEndpoint = `/api/v1/users`; 
            api.post({url:apiEndpoint, data:this.user})
            //axios.post(apiEndpoint, this.user)
                .then(response => {
                    this.loading = false;
                    const newUser = response.data;
                    this.$emit('success', newUser);
                    this.$bvModal.hide("ModalUser");
                })
                .catch(error => {
                    this.loading = false;
                    console.error("Error al crear el usuario:", error);
                });
        },

        updateUser() {
            this.loading = true;
            const apiEndpoint = `/api/v1/users`;
            // console.log(this.user);
            api.put({url:apiEndpoint, data:this.user, id:this.user.id})
            .then(response => {
                this.loading = false;
                const updatedUser = response.data;
                console.log(updatedUser);
                this.$emit('success', updatedUser);
                this.$bvModal.hide("ModalUser");
            })
            .catch(error => {
                this.loading = false;
                console.error("Error editing user:", error);
            });
        },

        loadSelectRole() {
            const apiEndpoint = `/api/v1/roles`;
            // api.get({url:apiEndpoint, params:{
            //     'status': 1,
            // }})
            api.get({url:apiEndpoint})
            .then(response => {
                console.log(response);
                this.selectedRoles = response.data.data;
            })
            .catch(error => {
            console.error("Error al cargar los roles:", error);
            });
        },
        loadSelectOptionsCenter(){
            const apiEndpoint = `/api/v1/centers`;
            api.get({url:apiEndpoint})
            .then(response => {
                this.selectOptionsCenter = response?.data?.data;
            })
            .catch(error => {
                console.error("Error cargando los centros:", error);
            });
        },
        submitForm() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            if(this.user?.id){
                // console.log(this.user);
                return this.updateUser();
            }
            return this.createUser();
        },
        cancelForm() {
            this.$v.$reset();
            this.$bvModal.hide("ModalUser");
            this.$emit('close-modal');
        }
    }
}
</script>
<template>
    <b-modal id="ModalUser" :title="modalTitle" size="xl">  
      <template #default="{  }">
        <form @submit.prevent="submitForm">
            <div class="row">
                <div class="card col-8">
                    <div class="card-header text-white bg-vertical-menu">Información general</div>
                    <!-- <div class="card-header text-white">Información general</div> -->
                    <div class="card-body">
                        <div class="row">
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="name">Nombre <span style="color: red;">*</span></label>
                                <input v-model="user.name" type="text" class="form-control" id="name" maxlength="200"  :class="{ 'is-invalid': $v.user.name.$error }">
                                <div v-if="$v.user.name.$error" class="invalid-feedback">
                                    <span v-if="!$v.user.name.required">{{ errorMessages.required }}</span>
                                    <span v-else-if="!$v.user.name.onlyLetters">{{ errorMessages.onlyLetters }}</span>
                                </div>
                            </div>
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="password">Password:</label>
                                <input v-model="user.password" type="password" class="form-control" id="password" required>
                            </div>
                            
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="email">Email: <span style="color: red;">*</span></label>
                                <input v-model="user.email" type="email" class="form-control" id="email" :class="{ 'is-invalid': $v.user.email.$error }">
                                <div v-if="$v.user.email.$error" class="invalid-feedback">
                                    <span v-if="!$v.user.email.required">{{ errorMessages.required }}</span>
                                    <span v-else-if="!$v.user.email.email">{{ errorMessages.email }}</span>
                                </div>
                            </div>
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="center_ids">Centro: <span style="color: red;">*</span></label>
                                <select v-model="user.center_id" class="form-control" id="center_ids">
                                    <option v-for="option in selectOptionsCenter" :value="option.id" :key="option.id">{{ option.name }}</option>
                                </select>
                            </div>
                            <div class="form-group col-md-4 col-xs-12">
                                <label for="dni">DNI: <span style="color: red;">*</span></label>
                                <input v-model="user.dni" type="text" class="form-control" id="dni" maxlength="10" :class="{ 'is-invalid': $v.user.dni.$error }">
                                <div v-if="$v.user.dni.$error" class="invalid-feedback">
                                    <span v-if="!$v.user.dni.required">{{ errorMessages.required }}</span>
                                    <span v-else-if="!$v.user.dni.noSpaces">{{ errorMessages.noSpaces }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="center">Convocatoria:</label>
                                <p>{{ user?.project?.name }}</p>
                            </div>
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="center">Uso:</label>
                                <p>{{ user?.use }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card col-4">
                    <div class="card-header text-white bg-vertical-menu">Estado</div>
                    <div class="card-body">
                        <div class="form-group">
                            <select class="form-control" id="selectedUserStatus" v-model="user.status">
                                <option v-for="option in userStatus" :value="option.id" :key="option.id">{{ option.label }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="role_ids">Roles:</label>
                            <select v-model="user.role_ids" class="form-control" id="role_ids" multiple>
                                <option v-for="option in selectedRoles" :value="option.id" :key="option.id">{{ option.name }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </form>
      </template>
  
      <template #modal-footer="{  }">
        <b-button variant="primary" @click="submitForm()" :disabled="loading"><span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="loading"></span> {{ submitButtonText }}</b-button>
        <b-button @click="cancelForm()">Cancel</b-button>
      </template>
    </b-modal>
</template>
